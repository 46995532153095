
import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { motion } from "framer-motion"
import { MotionDiv } from '../components/MotionCmp'

export const ToursSection = ({data}: any) => {
    const [tours, setTours] = useState<any[]>([])

    const allTours = useStaticQuery(graphql`
		query ToursQuery {
			allTour(sort: {fields: order, order: ASC}) {
                edges {
                    node {
                        tourId
                        title
                        subTitle
                        content
                        imageUrl
                        order
                        seo { url, title, description }
                    }
                }
            }
		}
  	`)

    useEffect(() => {
        const toursList: any[] = allTours ? allTours.allTour.edges : []
        setTours([...toursList.map(t => t.node)])
    }, [allTours])
    
    return (
        <section id="tours" className="section">
            <div className="section-divider">
                <MotionDiv initial={{ width: '5%' }} animate={{ width: '100%'}} transition={{ duration: 0.6, delay: 0}} className="divider"></MotionDiv>
                <div className="section-divider-title">
                    <MotionDiv>
                        <h6 className="section-title">TOURS</h6>
                    </MotionDiv>
                    <div className="section-bullet"><ion-icon name="radio-button-on-outline"></ion-icon></div>
                </div>
            </div>
            <div className="section-content">
                <div className="tours">
                    {tours.map((tour, i) => 
                        <MotionDiv key={i} className="tour">
                            <h2 className="tour-title"><Link to={`/tours/${tour.tourId}`}>{tour.title}</Link></h2>
                        </MotionDiv>
                    )}
                </div>
                <div className="tour-abs m-hidden">
                    <MotionDiv initial={{ opacity: 0, x: -150 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5, delay: 0.5 }} className="abs">
                        <h2>TOURS</h2>
                    </MotionDiv>
                </div>
            </div>
        </section>
    )
}