import React, { useState } from 'react'
import { motion } from "framer-motion"
import Header from '../components/Header'
import { MotionDiv } from '../components/MotionCmp'
import { ToursSection } from '../layout/ToursSection'
import { Footer } from '../components/Footer'
import SEO from '../components/SEO'
import { useStore } from '../store/store-actions'

export default function Home() {
	const [imageLoading, setImageLoading] = useState(true)
	const {store} = useStore()

	const imageLoaded = () => {
		setImageLoading(false)
	}

	return (
		<>
			<SEO title="Home" description={`Tours y paquetes personalizados a Machu Picchu. Salidas todos los días. Contactar ${store.site.phone}`} />

			<div className="container">

				<Header />

				<section id="intro" className="section">
					<div className="intro">
						<div className="row">
							<div className="col-sm-6">
								<motion.img
									initial={{ opacity: 0 }}
									animate={{ opacity: imageLoading ? 0 : 1 }}
									transition={{ delay: 0, duration: 1 }} 
									onLoad={imageLoaded}
									src="https://firebasestorage.googleapis.com/v0/b/mapi-viajes.appspot.com/o/web%2Fmachu-picchu-tour.jpg?alt=media&token=bbb0b368-2b63-4a08-8cd5-4faa5c20b101" 
								/>
							</div>
							<div className="col-sm-6">
								<div className="heading-text">
									<h3>
										<span><motion.span initial={{ y: 100 }} animate={{ y: 0 }} transition={{ duration: 0.6, delay: 0.4 }}>TOURS PERSONALIZADOS</motion.span></span><br></br>
										<span><motion.span initial={{ y: 100 }} animate={{ y: 0 }} transition={{ duration: 0.6, delay: 0.6 }}>A MACHU PICCHU</motion.span></span>
									</h3>
									<div className="gap-20"></div>
									<a href="#tours" className="btn-tours">Ver Tours &nbsp;<ion-icon name="arrow-forward-outline" color="white" className="btn-icon"></ion-icon></a>
								</div>
							</div>
						</div>
					</div>
				</section>

				<ToursSection />

				<Footer />
			</div>
		</>
	)
}